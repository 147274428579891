import React from 'react'
import Banner1 from '../components/elements/Banner1'
import Hero from '../components/elements/Hero'
import Section1 from '../components/elements/Section1'
import Section2 from '../components/elements/Section2'
import Section3 from '../components/elements/Section3'
import Section4 from '../components/elements/Section4'
import Section5 from '../components/elements/Section5'
import Hero1 from '../components/elements/desktop/Hero1'
import Building from '../components/elements/desktop/Building'
import AboutUs from '../components/elements/desktop/AboutUs'
import OurAim from '../components/elements/desktop/OurAim'
import Form from '../components/utils/Form'
function Home() {
  return (
    <>
    <Form/>
    <Hero1/>
    <Hero/>
    <Building/>
    <Banner1/>
    <AboutUs/>
    <Section1/>
    <Section2/>
    <Section3/>
    <OurAim/>
    <Section4/>
    <Section5/>
    {/* <Section6/> */}
    </>
  )
}

export default Home