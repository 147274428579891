import React from "react";
import { IoIosArrowForward } from "react-icons/io";
function Section3() {
  return (
    <div className="container-fluid aim">
      <div className="row">
        <div className="col-md-12">
          <h1>Our Aim</h1>
        </div>
      </div>

      <div className="row">
        <div
          className="col-md-6 col-12"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          <div className="aim-left">
            <div className="content mx-5">
              <h2>Mission</h2>
              <p>
                Our mission is to prevent speculation and displacement of small
                businesses and to create a dynamic and inclusive community for
                both neighborhoods. Through our initiatives, we aim to improve
                the overall quality of life in Central Brooklyn by upholding
                strong partnerships and building a resilient local economy.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="row" style={{ marginTop: "-100px" }}>
            <div className="col-md-6 col-6">
              <img src="assets/aim1.svg" alt="" className="res-img" />
            </div>
            <div className="col-md-6 col-6">
              <img src="assets/aim2.svg" alt="" className="res-img" />
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div className="row mt-5">
        <div
          className="col-md-6 col-12"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          <div className="aim-left" style={{ background: "#8BC63E" }}>
            <div className="content mx-5">
              <h2>Vision</h2>
              <p>
                The Vision of Little Bangladesh Merchants Association is to build a
                community where local commerce and culture are celebrated.
                Through our partnerships, programs, and initiatives, we aim to
                empower local businesses, promote economic development, and
                improve the quality of life for all residents of Brooklyn.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="row" style={{ marginTop: "-100px" }}>
            <div className="col-md-6 col-6">
              <img src="assets/aim3.svg" alt="" className="res-img" />
            </div>
            <div className="col-md-6 col-6">
              <img src="assets/aim4.svg" alt="" className="res-img" />
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default Section3;
