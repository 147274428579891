import React from "react";

function AboutUs() {
  return (
    <div className="container-fluid d-aboutUs">
      <div className="row">
        <div className="col-md-12">
          <h1>About us</h1>
          <p>Elevating Local Businesses, Enhancing Neighborhoods</p>
        </div>
      </div>

      <div className="row">
        <div
          className="col-md-6"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          <div className="about-bg1">
            <p>
              B.E is a group of local merchants dedicated to promoting and
              supporting businesses in neighborhoods A & B. Our members include
              a diverse range of shops and services, from retail stores and
              restaurants to professional services and more. We are a
              forward-thinking merchant services association that is leading the
              charge.
            </p>
            <p>
              {" "}
              Brooklyn Emerge (B.E) is a non-profit organization that was formed
              to facilitate neighborhoods A & B. Since our inception, we have
              been working tirelessly to improve the lives of those in need
              through a wide range of initiatives. We are working for the
              enhancement of neighborhoods A & B with partnerships of local
              businesses, government, and other community stakeholders to
              provide civic and economic opportunities to the residents of
              Central Brooklyn.
            </p>
          </div>
        </div>
        <div
          className="col-md-6"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          <div className="about-bg1 bg-2">
            <p>
              One of our key focus areas is connecting merchant organizing to
              the needs of the commercial corridor. We aim to identify current
              conditions in the targeted commercial corridor, the specific needs
              arising from these conditions, and how merchant organizing would
              address those needs.
            </p>
            <p>
            Our goal is to prevent speculation and
              displacement of small businesses, and we consider the conditions
              and factors that may determine whether collaborative partnerships
              are effective. We initiate a collaborative partnership an alliance
              among merchants from multiple sectors working together to achieve
              a common purpose. The collaborative partnerships attempt to
              improve conditions and outcomes related to the businesses, foster
              partnerships, and build local capacity to steward ongoing
              commercial revitalization that is benefited by the entire
              communities.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
