import React from "react";
import {RiDoubleQuotesL} from 'react-icons/ri'
function Building() {
  return (
    <div className="container-fluid  building">
      <div className="row">
        <div className="circle"></div>
        <div className="col-md-1"></div>
        <div className="col-md-6">
          <h1>Building a Thriving Commercial Corridor, Together</h1>
            <div className="d-flex align-items-center">
        <RiDoubleQuotesL size={70}/>
          <p className="mt-2 mx-1 para">
            Our objective is to augment the distinctiveness and appeal of Little
            Bangladesh through a diversity of small businesses,
          </p>
            </div>
          <p className="para1">
          utilizing specialized events, initiatives, funding
            opportunities, sponsorships, and collaborative partnerships to
            increase commercial activity and patronage in the area.
          </p>
          <button>Read more</button>
        </div>

        <div className="col-md-4">
            <img src="assets/11.svg" alt="" className="orange-circle"/>
        </div>
      </div>
    </div>
  );
}

export default Building;
