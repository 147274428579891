import React from 'react'
import Hero from '../components/elements/desktop/Hero1'
import Building from '../components/elements/desktop/Building'
import AboutUs from '../components/elements/desktop/AboutUs'

function Desktop() {
  return (
    <>
    <Hero/>
    <Building/>
    <AboutUs/>
    </>
  )
}

export default Desktop