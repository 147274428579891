import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import Carousel from "better-react-carousel";

function Section5() {
  return (
    <div className="container services">
      <div className="row">
        <div className="col-md-12">
          <h1>Our Services</h1>
          <p>
            We offer specialized services and work on a variety of social
            development initiatives and projects. We have achieved substantial
            success in sustainable community development. In addition, we have
            focused on other areas such as engaging and mobilizing communities,
            revitalizing businesses, preventing hunger, promoting education,
            promoting environmental sustainability, developing skills, and
            building capacity. Our goal is to assist and empower disadvantaged
            Americans through our long-term social service programs.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-12">
          <div
            style={{
              background: "white",
              padding: "30px",
              position: "relative",
            }}
          >
            <div className="caro-item">
              <div className="caro-box">
                <img src="assets/hands.svg" alt="" />
              </div>
              <h1>Long-term Business</h1>
              <p>
                B.E's merchant organization services help businesses establish
                strong long-term relationships with other businesses in the
                community.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-12">
          <div
            style={{
              background: "white",
              padding: "30px",
              position: "relative",
            }}
          >
            <div className="caro-item" style={{background:'#F7931D'}}>
              <div className="caro-box">
                <img src="assets/21.svg" alt="" />
              </div>
              <h1>Enhanced Professional Ties</h1>
              <p>
                Our services promote professional connections among business
                owners, which allows them to share information and resources.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-12">
          <div
            style={{
              background: "white",
              padding: "30px",
              position: "relative",
            }}
          >
            <div className="caro-item" style={{background:'#1074BC'}}>
              <div className="caro-box">
                <img src="assets/22.svg" alt="" />
              </div>
              <h1>Shared Technical Support</h1>
              <p>
                B.E provides technical support to businesses in areas such as
                marketing and technology, to help them improve their operations
                and grow.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-12">
          <div
            style={{
              background: "white",
              padding: "30px",
              position: "relative",
            }}
          >
            <div className="caro-item" style={{background:'#24AAE1'}}>
              <div className="caro-box">
                <img src="assets/23.svg" alt="" />
              </div>
              <h1>Business Growth Guidance</h1>
              <p>
                Our organization offers guidance and advice to businesses on how
                to expand and improve their operations.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-12">
          <div
            style={{
              background: "white",
              padding: "30px",
              position: "relative",
            }}
          >
            <div className="caro-item" style={{background:'#3AB54A'}}>
              <div className="caro-box">
                <img src="assets/24.svg" alt="" />
              </div>
              <h1>Business Growth Guidance</h1>
              <p>
                Our organization offers guidance and advice to businesses on how
                to expand and improve their operations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section5;
