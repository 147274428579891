import React from 'react'

function Header() {
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
    <div className="container">
      <a className="navbar-brand" href="#"><img src="assets/logo.svg" alt="" width={150} /></a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="#">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Updates</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href='/'>Services</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href='/'>Features</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href='/'>About Us</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  
  )
}

export default Header