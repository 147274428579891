import React from "react";
import { FaLinkedinIn, FaFacebookF, FaDiscord } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
function Footer() {
  return (
    <div className="container footer">
      <div className="row footer-list">
        <div className="col-md-3 m-auto col-12 text-center">
          <img src="assets/logo.svg" alt="" />
          <p className="mt-3">
            Our objective is to augment the distinctiveness and appeal of Little
            Bangladesh through a diversity of small businesses,
            utilizing specialized events, initiatives, funding opportunities,
            sponsorships, and collaborative partnerships to increase commercial
            activity and patronage in the area.
          </p>
        </div>
        <div className="col-md-3 m-auto col-7">
          <h2>Our Services</h2>
          <ul className="list-unstyled">
            <li>
              <a href="/">Long-term Business </a>
            </li>
            <li>
              <a href="/">Enhanced Professional Ties</a>
            </li>
            <li>
              <a href="/"> Shared Technical Support</a>
            </li>
            <li>
              <a href="/">Business Growth Guidance</a>
            </li>
            <li>
              <a href="/">Best Practices and Lessons</a>
            </li>
          </ul>
        </div>
        <div className="col-md-2 col-5">
          <h2>Informtion</h2>
          <ul className="list-unstyled">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/">About Us</a>
            </li>
            <li>
              <a href="/">Testimonials</a>
            </li>
            <li>
              <a href="/">Our Aim</a>
            </li>
            <li>
              <a href="/">Services</a>
            </li>
          </ul>
        </div>
        <div className="col-md-4 col-12">
        <h2>Subscribe</h2>
        <p className="text-start">Signup to our weekly newsletter service and stay posted
</p>
<input type="text" name="" id="" className="form-control" placeholder="subscribe"/>
<div className="mt-4">
              <a href="/"><img src="assets/facebook 1.svg" alt="" /></a>
              <a href="/" className="mx-2"><img src="assets/instagram 1.svg" alt="" /></a>
              <a href="/" className="mx-2"><img src="assets/twitter 1.svg" alt="" /></a>
              <a href="/" className="mx-2"><img src="assets/linkedin 1.svg" alt="" /></a>
            </div>
        </div>
      </div>

      <div className="row ">
        <div className="col-md-12 ">
          <hr />
          <p>Copyright © 2022 Merchant Association - All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
