import React from "react";
import { ImQuotesLeft } from "react-icons/im";
function Banner1() {
  return (
    <div className="container banner1 mt-5">
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6">
          <h1>Building a Thriving Commercial Corridor, Together</h1>
        </div>
        <div className="col-md-3 col-12">
          <img src="assets/11.svg" alt="" className="res-img"/>
        </div>
        <div className="col-md-1 col-3">
          <ImQuotesLeft size={70} />
        </div>
        <div className="col-md-10 col-9">
          <h4 className="">
            Our objective is to augment the distinctiveness and appeal of
            Little9Bangladesh and Bath Avenue through a diversity of small{" "}
          </h4>
        </div>
        <h4>
          businesses, utilizing specialized events, initiatives, funding
          opportunities, sponsorships, and collaborative partnerships to
          increase commercial activity and patronage in the area.
        </h4>
      </div>

      <div className="row mt-4">
        <div className="col-md-6 col-6">
        <button>Read More</button>
        </div>
        <div className="col-md-6 col-6">
          <img src="assets/vector.svg" alt="" />
        </div>
      </div>
    </div>
    // </div>
  );
}

export default Banner1;
