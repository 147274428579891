import React from "react";

function Hero() {
  return (
    <div className="container-fluid hero-container hero-bg">
      <div className="row">
        <div className="col-md-12">
              <h1>
                Empowering Local Businesses, Building Stronger Communities
              </h1>
              <p>
                Our objective is to augment the distinctiveness and appeal of
                Little Bangladesh and Bath Avenue through a diversity of small
                businesses, utilizing specialized events, initiatives, funding
                opportunities, sponsorships, and collaborative partnerships to
                increase commercial activity and patronage in the area.
              </p>
              <button>Read More</button>
        </div>
        </div>
        <div className="row">          
        <div className="col-md-6 col-6 pad-col">
         {/* <img src="assets/res-img1.svg" alt="" className=" res-img"/> */}
          </div>
          <div className="col-md-6 col-6 pad-col">
            <div className="d-flex">
            {/* <img src="assets/container.svg" alt="" className="res-img res-sm-img"/> */}
            {/* <img src="assets/res-img2.svg" alt="" className="res-img "/> */}
            </div>
          </div>
        </div>
      </div>
  );
}

export default Hero;
