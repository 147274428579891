import React from "react";
import { IoIosArrowForward } from "react-icons/io";

function Section4() {
  return (
    <div className="container-fluid sec4">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>Join Hands With B.E</h1>
            <h2>Strengthening our communities.</h2>
            <p>
              We invite you to join our association, to become more involved in
              your <br /> community and grow your business. Together, we can make a
              difference in the <br /> lives of our merchants, customers, and
              community. Let's strive to achieve our <br /> mission and vision, and
              make our community a better place for all.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section4;
