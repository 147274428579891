import React from "react";

function Hero1() {
  return (
    <div className="container-fluid d-hero">
      <div className="row">
        <div className="col-md-3">
          <img src="assets/hero.svg" alt="" />
        </div>
        <div className="col-md-9 d-hero-col">
            <div className="hero-content">

          <h1>Empowering Local Businesses, Building Stronger Communities</h1>
          <p>
            Our objective is to augment the distinctiveness and appeal of Little
            Bangladesh through a diversity of small businesses,
            utilizing specialized events, initiatives, funding opportunities,
            sponsorships, and collaborative partnerships to increase commercial
            activity and patronage in the area.
          </p>
          <button>Read more</button>
            </div>
          <div className="d-circle"></div>
        </div>
      </div>
    </div>
  );
}

export default Hero1;
