import React from "react";

function Section2() {
  return (
    <div className="container testimonials">
      <div className="row">
        <div className="col-md-12 text-center">
          <h1 className="mb-0">Testimonial</h1>
          <h3>"Revitalizing Central Brooklyn, Together" - Our Happy Clients</h3>
        </div>
      </div>

      <div className="row mt-5 m-auto ">
        {/* <div className="col-md-1"></div> */}
        <div className="col-md-4 ">
          <div className="box ">
            <img src="assets/quotes.svg" alt="" />
            <p>
              "I never expected to see such a positive impact on my business
              from working with Brooklyn Emerge. Not only did they help me
              improve my storefront and attract more customers, but they also
              connected me with a network of local businesses that have become
              valuable partners and friends. The community events and festivals
              they organize have also been great opportunities for me to
              showcase my products and services. I'm so grateful for the support
              and opportunities B.E has provided.”
            </p>
            <span>- Liz Gosselin</span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="box">
            <img src="assets/quotes.svg" alt="" />
            <p>
              "I've been working with Brooklyn Emerge for a few months now and I
              have to say, I'm impressed. They've helped my small business in so
              many ways. From connecting me with other local merchants to
              providing guidance on how to improve my sales, B.E has been a
              valuable resource for me. I also appreciate the community events
              they organize, like the Eid festival, which has helped attract new
              customers to my store. Overall, I highly recommend collaborating
              with Brooklyn Emerge if you're a small business owner looking to
              grow and succeed."
            </p>
            <span>- Shane Ridley</span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="box ">
            <img src="assets/quotes.svg" alt="" />
            <p>
              "Working with Brooklyn Emerge has been a game changer for my small
              business. They helped me connect with other local merchants and
              provided valuable resources and guidance for growing my business.
              I've seen a significant increase in sales and customer engagement
              since joining the merchant association. I highly recommend
              Brooklyn Emerge to any small business owner looking to thrive in
              their community."
            </p>
            <span>- Amey Walvekar</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
