import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
function Form() {
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [data, setData] = useState({
        name:'',
        email:'',
        password:''
    })

    const handleChange =(event) =>{
        setData((prevState) => ({
            ...prevState,
            [event.target.name] : event.target.value 
        }))   
    }
    const handleSubmit =(e)=>{
        e.preventDefault();
        const details = {
            Name: data.name,
            Email: data.email,
            Password: data.password
        }
        console.log(details)
        setData.name('')
    //     axios.get('https://sheet.best/api/sheets/1d5ea6e0-c6ae-456e-b5a4-8ba0c2b8588a?1vJhQluriu1VVHyuy5lyQT0TXkQGsj5ny0WZ5vJz6jqY', details)
    //     .then((res) => {
    //         console.log("🚀 ~ file: Form.jsx:34 ~ .then ~ res:", res)
    //     })
    // .catch((error)=>{
    //     console.log(error)
    // })
    }
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Sign Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col-md-12">
                    <form action="/" onSubmit={handleSubmit}>

                    <div className='d-flex flex-column'>
                <span>Username</span>
                <input type="text" name="name" value={data.name} onChange={handleChange} placeholder='Username'/>
                    </div>
                    <div className='d-flex flex-column'>
                <span>Email</span>
                <input type="text" name="email" id="" onChange={handleChange} placeholder='Enter Email'/>
                    </div>
                    <div className='d-flex flex-column'>
                <span>Password</span>
                <input type="text" name="password" id="" onChange={handleChange} placeholder='Enter Password'/>
                    </div>
                    <div className='d-flex align-items-baseline'>
                        <div className='mt-3 d-flex '>

                <input type="checkbox" name="" id="" style={{width:'30px',height:'30px'}}/>
                <p className='mx-3'>By continuing, you agree to our Terms of Use 
and have read and understood our Privacy policy.</p>
                    </div>
                        </div>

                    <button>Submit</button>
                </form>
                </div>

            </div>
        </Modal.Body>
      </Modal>
    </>
  
  )
}

export default Form